.App {
  text-align: left;
  margin-top: 3%;
  margin-bottom: 5%;
  min-height: 100vh;
  margin-left: 20%;
  margin-right: 20%;

  @media (max-width: 1024px) {
    margin-left: 10%;
    margin-right: 10%;
  }
  
}

